var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0O737DOcQUEhkh99aHwLf"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

import { env } from "./src/env";

Sentry.init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  dsn: env.NEXT_PUBLIC_SENTRY_DSN,

  // disabled in development
  enabled: env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT !== "development",

  // Added missing env variable for tracing separate env's
  environment: env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      blockAllMedia: true,
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
    }),
  ],

  replaysOnErrorSampleRate: 1.0,

  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // Overwrite the defaults to ensure no trace headers are sent
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/everfund\.com\/api/,
    /^https:\/\/dashboard\.everfund\.com\/api/,
  ],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,
});
